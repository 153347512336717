import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "nandighosha", For eg: "newlogoname",
  const [tabs] = useState([
    "otv",
    "news18odia",
    "kanaknews",
    "kalingatv",
    "nandighosha",
  ]);

  const [selectedOdiaTab, setSelectedOdiTab] = useState<string>(
    () => localStorage.getItem("selectedOdiaTab") || "otv"
  );

  console.log(selectedOption);
  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedOdiTab(channelKey);
    localStorage.setItem("selectedOdiaTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedOdiaTab");
    if (savedTab) {
      setSelectedOdiTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["cnbctv18", "news18odia"];
  const hiddenTabs: any = ["kalingatv"];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedOdiaTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedOdiTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const otv = `/assets/channel-logos/otv.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const news18odia = `/assets/channel-logos/news18-odia.png?v=${cacheBuster}`;
  const kanaknews = `/assets/channel-logos/kanak-news.png?v=${cacheBuster}`;
  const kalingatv = `/assets/channel-logos/kalinga-tv.png?v=${cacheBuster}`;
  const nandighosha = `/assets/channel-logos/nandighosha.png?v=${cacheBuster}`;

  const imagesToPreload = [otv, news18odia, kanaknews, kalingatv, nandighosha];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Odia News Live | Watch OTV, News18, Kanak, Kalinga, Nandighosha TV
          </title>
          <meta
            name="description"
            content="Watch live streaming of top Odia news channels: OTV, News18 Odia, Kanak News, Kalinga TV, and Nandighosha TV. Stay updated with real-time news from Odisha and beyond."
          />
          <meta
            name="keywords"
            content="Odia news live, Odisha news live, OTV live, News18 Odia live, Kanak News live, Kalinga TV live, Nandighosha TV live, Odia breaking news, Odisha latest news, live Odia channels"
          />
        </Helmet>
        <Header />

        {selectedOption === "Odia" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div (from line no.104 to 123) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("otv") && (
                  <div
                    className={`tab-item ${
                      selectedOdiaTab === "otv" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("otv")}
                  >
                    {/**** When you add new logo change the src={otv} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={otv}
                        alt="OTV Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={otv} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("news18odia") && (
                  <div
                    className={`tab-item ${
                      selectedOdiaTab === "news18odia" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("news18odia")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18odia}
                        alt="News18 Odia Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("kanaknews") && (
                  <div
                    className={`tab-item ${
                      selectedOdiaTab === "kanaknews" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("kanaknews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={kanaknews}
                        alt="Kanak News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("kalingatv") && (
                  <div
                    className={`tab-item ${
                      selectedOdiaTab === "kalingatv" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("kalingatv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={kalingatv}
                        alt="Kalinga TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("nandighosha") && (
                  <div
                    className={`tab-item ${
                      selectedOdiaTab === "nandighosha" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("nandighosha")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={nandighosha}
                        alt="Nandighosha TV live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedOdiaTab === "otv" && visibleTabs.includes("otv") && (
                <>
                  <h1>OTV Live</h1>
                  <iframe
                    width="100%"
                    height="450px"
                    src={`https://www.youtube.com/embed/9T7VpBKdu68?si=856RfBYdBtmRAgGB&autoplay=1&v=${cacheBuster}`}
                    title="OTV Live"
                    loading="lazy"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </>
              )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedOdiaTab === "news18odia" &&
                visibleTabs.includes("news18odia") && (
                  <>
                    <h1>News18 Odia Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/ZEaPzl1uSQw?si=vlgZ_d2CP79PSXVb&autoplay=1&v=${cacheBuster}`}
                      title="News18 Odia Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedOdiaTab === "kanaknews" &&
                visibleTabs.includes("kanaknews") && (
                  <>
                    <h1>Kanak News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/tL5zlzxpYqE?si=7FlZjajojm0MJtq8&autoplay=1&v=${cacheBuster}`}
                      title="Kanak News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedOdiaTab === "kalingatv" &&
                visibleTabs.includes("kalingatv") && (
                  <>
                    <h1>Kalinga TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Xor8AoK7Ggc?si=HM3pFFTFH5-fu3uP&autoplay=1&v=${cacheBuster}`}
                      title="Kalinga TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedOdiaTab === "nandighosha" &&
                visibleTabs.includes("nandighosha") && (
                  <>
                    <h1>Nandighosha TV live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/cSZBbt17bzI?si=BuN2SlId5P5ZzR6b&autoplay=1&v=${cacheBuster}`}
                      title="Nandighosha TV live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}

        <p style={{ display: "none" }}>
          Welcome to Odia News Live, your one-stop destination for live
          streaming of the top Odia news channels. Stay updated with real-time
          news and breaking headlines from Odisha and around the world. Watch
          live streams of popular channels like OTV, News18 Odia, Kanak News,
          Kalinga TV, and Nandighosha TV. Whether it's politics, entertainment,
          sports, or local news, we've got you covered. Bookmark our page for
          uninterrupted access to reliable news in the Odia language.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
